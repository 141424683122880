export default {
  title: 'Fitify - Human Coaching',
  description: 'Fitify - Human Coaching',
  openGraph: {
    type: 'website',
    locale: 'en',
    url: '#',
    site_name: 'Fitify - Human Coaching',
    images: [
      { url: 'https://static.gofitify.com/images/og/fitify-plus-og-image.png' },
    ],
  },
  twitter: {
    handle: '',
    site: '',
    cardType: 'summary_large_image',
  },
}
